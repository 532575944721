@import '~antd/dist/antd.less';
@import './conf/theme/global-overrides.less';

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  src: local('Mnarope'), url('./fonts/manrope.woff2') format('woff2'), 
}

@gray-1: #ffffff;@gray-2: #fafafa;@gray-3: #f5f5f5;@gray-4: #f0f0f0;@gray-5: #d9d9d9;@gray-6: #bfbfbf;@gray-7: #8c8c8c;@gray-8: #595959;@gray-9: #434343;@gray-10: #262626;@gray-11: #1f1f1f;@gray-12: #141414;@gray-13: #000000;@font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@code-family: 'Roboto Mono', Consolas, 'Liberation Mono', Menlo, Courier, monospace;@border-radius-base: 5px;@font-size-base: 12px;@font-size-sm: 10px;@text-color-secondary: fade(black, 45%);@btn-font-weight: 600;@btn-border-style: @border-style-base;@btn-shadow: 0px 0px 4px 0px #0000001a;@btn-default-border: @gray-5;@input-border-color: @gray-5;@select-border-color: @gray-5;@typography-title-font-weight: 700;@tag-default-bg: transparent;@tag-default-color: @gray-9;@layout-header-height: 60px;@layout-header-padding: 0 20px;@primary-color: #1890ff;@primary-color-light: #F0F5FF;@primary-color-dark: #002766;@layout-header-background: white;@layout-header-color: #434343;@layout-body-background: white;@component-background: white;@body-background: white;@border-color-base: #ececec;@text-color: fade(black, 85%);@heading-color: fade(black, 85%);@background-color-light: hsv(0, 0, 98%);@divider-color: fade(black, 6%);@disabled-color: fade(black, 25%);@steps-nav-arrow-color: fade(black, 25%);@homepage-background-upper-fade: #FFFFFF;@homepage-background-lower-fade: #FFFFFF;@homepage-text-color: #434343;@box-shadow: 0px 0px 30px 0px rgb(239 239 239);@box-shadow-hover: 0px 1px 0px 0.5px rgb(239 239 239);